<template>
  <div class="base_Login">
    <div class="contenedor" style="width:300px">
      <div class="columna">
        <div class="conflex">
          <span :class="$cfg.app.bar.titulo_class">
            {{ $cfg.app.bar.titulo }}
            <span :class="$cfg.app.bar.subtitulo_class">{{
              $cfg.app.bar.subtitulo
            }}</span>
          </span>
        </div>
      </div>

      <div class="columna">
          <span style="font-size:20px">{{' Iniciar sesión' }}</span>
      </div>

      <div class="columna">
          <div class="user">
              <v-text-field
                v-model="user"
                outlined
                dense
                placeholder="Usuario"
                :append-icon="step=='pass'? '' : 'mdi-arrow-right'"
                @click:append="send_user"
                @keydown="onkeyDown($event, 'user')"
              >
              </v-text-field>
            </div>
   
          <div v-show="step=='pass'">
              <v-text-field
                v-model="password"
                ref="inputPass"
                outlined
                dense
                placeholder="Password"
                append-icon="mdi-arrow-right"
                type="password"
                @click:append="send_password('pass')"
                @keydown="onkeyDown($event, 'pass')">
              </v-text-field>
         
          </div>
     
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step:'user',
      user: "",
      password: "",
      user_logued: ""
    };
  },

  methods: {
    send_user() {
      if (!this.user.length) return;
      this.onClick('user');
      this.step= "pass";
    },

    send_password() {
      if (!this.password.length) return;
      this.onClick('pass');
    },

    onClick(accion) {
      this.$emit("onClick", accion, this.user, this.password);
    },

    onkeyDown(e, accion) {
      if (e.keyCode !== 13) return;
      accion== 'user'? this.send_user() : this.send_password();
    }
  },


  watch: {
    step() {
      if (this.step!= 'pass') return;
      
      this.user_logued= JSON.parse(JSON.stringify(this.user));
      this.$refs.inputPass.focus();
    },

    user() {
      if (this.user_logued== "") return;
      if (this.user_logued!= this.user) this.step= 'user';
    }
  }
};
</script>

<style>
/*     .loginBase {
        position:absolute;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -240px;
        width: 400px;
        height: auto;
        text-align:center;          
        border: 1px solid lightgray;
        border-radius: 10px;                 
    } */


/* .logo img {
  height: 60px;
} */
.titulo span {
  font-size: 22px;
}

/* textfields global */
.tf {
  padding: 0px 15px 0px 15px;
}
.tf input {
  font-size: 15px;
}

/* textfield particular */
.tf .input_user,
.tf .input_pass {
  padding-top: 35px;
}
.tf .input_captcha {
  padding-top: 20px;
}

/* captcha */
.captcha {
  padding-top: 30px;
}
.captcha .letters {
  display: inline;
}
.captcha .reload {
  display: inline;
}

/* botones de acciones */
.btn_next {
  text-align: center;
  padding: 25px 15px 25px 0px;
}
.btns_pass {
  display: inline-block;
  padding: 25px 0px 25px 0px;
}
.btns_pass .btn_before {
  display: inline-block;
  padding-right: 20px;
}
.btns_pass .btn_end {
  display: inline-block;
}
</style>
